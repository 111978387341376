@mixin animation {
  opacity: 0;
  animation-duration: 4s;
  animation-fill-mode: backwards;
  animation-iteration-count: infinite;
}

.vivica {
  @include animation;
  animation-name: vivica;
}

.patient {
  @include animation;
  animation-name: patient;
}

.line {
  @include animation;
  animation-name: liner;
}

@keyframes vivica {
  from {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes patient {
  from {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes liner {
  from {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
