@import "./fonts.scss";
body {
  font-family: Raleway, RalewayLocal, sans-serif;
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-feature-settings: "lnum";
  -moz-font-feature-settings: "lnum";
  font-feature-settings: "lnum";
  * {
  -webkit-font-feature-settings: "lnum";
  -moz-font-feature-settings: "lnum";
  font-feature-settings: "lnum";
  }
}


/* Настройка scroll bar */
::-webkit-scrollbar {
  width: 10px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #DFF2F5;
}

.grecaptcha-badge { visibility: hidden; }