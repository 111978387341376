.react-datepicker {
  padding: 24px;
  background: #4b5563;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__header {
  border-bottom: none;
  background: #4b5563;
}

.react-datepicker__month-container {
  color: #f3f4f6;
  background: #4b5563;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header,
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: inherit;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 50%;
  background: #19a1b7;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover,
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  border-radius: 50%;
  background: #19a1b7;
}

.react-datepicker__day.react-datepicker__day--in-range:hover {
  border-radius: 0;
}

.react-datepicker__day-names {
  margin-top: 22px;
}

.react-datepicker__day-name {
  margin: 0;
  width: 40px;
}

.react-datepicker__week {
  display: flex;
}

.react-datepicker__day {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  width: 40px;
  height: 40px;
  font-size: 14px;
  line-height: 0;
}

.react-datepicker__current-month {
  font-size: 16px;
}

.react-datepicker__navigation-icon::before {
  border-width: 2px 2px 0 0;
  border-color: #f3f4f6;
}

.react-datepicker__navigation {
  top: auto;
}

.react-datepicker__navigation--previous {
  left: 40px;
}

.react-datepicker__navigation--next {
  right: 40px;
}

.react-datepicker__day--in-range {
  border-radius: 0;
  background: rgb(96 210 233 / 70%);
}

.react-datepicker__day--range-start {
  border-radius: 50% 0 0 50%;
  background: #19a1b7;
}

.react-datepicker__day--range-end {
  border-radius: 0 50% 50% 0;
  background: #19a1b7;
}

.react-datepicker__day--range-start {
  border-radius: 50% 0 0 50%;
  background: #19a1b7;
}

.react-datepicker__day--range-start.react-datepicker__day--range-end {
  border-radius: 50%;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
  border-radius: 0;
  background-color: rgb(96 210 233 / 70%);
}

.react-datepicker__day--outside-month {
  opacity: 0;
}

.react-datepicker__close-icon {
  padding-right: 23px;

  &::after {
    content: '';
    border-radius: 0;
    background-image: url('/src/shared/assets/icons/24x24/cross-sm.svg');
    background-position: center;
    background-color: transparent;
  }
}
