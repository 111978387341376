@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

@font-face {
  font-family: 'RalewayLocal';
  src: url('../resources/fonts/Raleway/static/Raleway-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'RalewayLocal';
  src: url('../resources/fonts/Raleway/static/Raleway-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'RalewayLocal';
  src: url('../resources/fonts/Raleway/static/Raleway-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'RalewayLocal';
  src: url('../resources/fonts/Raleway/static/Raleway-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'RalewayLocal';
  src: url('../resources/fonts/Raleway/static/Raleway-ExtraBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'RalewayLocal';
  src: url('../resources/fonts/Raleway/static/Raleway-ExtraBoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'RalewayLocal';
  src: url('../resources/fonts/Raleway/static/Raleway-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'RalewayLocal';
  src: url('../resources/fonts/Raleway/static/Raleway-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'RalewayLocal';
  src: url('../resources/fonts/Raleway/static/Raleway-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'RalewayLocal';
  src: url('../resources/fonts/Raleway/static/Raleway-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'RalewayLocal';
  src: url('../resources/fonts/Raleway/static/Raleway-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'RalewayLocal';
  src: url('../resources/fonts/Raleway/static/Raleway-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'RalewayLocal';
  src: url('../resources/fonts/Raleway/static/Raleway-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'RalewayLocal';
  src: url('../resources/fonts/Raleway/static/Raleway-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'RalewayLocal';
  src: url('../resources/fonts/Raleway/static/Raleway-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'RalewayLocal';
  src: url('../resources/fonts/Raleway/static/Raleway-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'RalewayLocal';
  src: url('../resources/fonts/Raleway/static/Raleway-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-display: block;
}

@font-face {
  font-family: 'RalewayLocal';
  src: url('../resources/fonts/Raleway/static/Raleway-Thin.ttf') format('truetype');
  font-weight: 100;
  font-display: block;
}

body {
  -webkit-font-feature-settings: "lnum";
-moz-font-feature-settings: "lnum";
font-feature-settings: "lnum";
}