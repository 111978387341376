.wrapper {
  padding: 0;
  overflow-x: auto;
}

.row {
  &:hover {
    background-color: initial;
  }
}

.arrow {
  transform: rotate(-90deg);
}
.header,
.body {
  width: fit-content;
  min-width: 100%;
}

.row_table_dynamics {
  padding: 10px 40px 10px 40px;
  min-height: 40px;
}
